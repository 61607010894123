.account-detail-title {
  margin-bottom: 20px;
}

.save-update-button {
  color: #0288d1 !important;
  border: 2px solid #0288d1 !important;
  font-weight: 600 !important;
}
.cancel-update-button {
  color: #fff !important;
  border: 2px solid #f6416c !important;
  background-color: #f6416c !important;
  font-weight: 600 !important;
}
/* Settings */
.admins-container {
  background: #fff;
  padding: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.add-admin-container {
  height: 170px;
  width: 170px;
  border-radius: 16px;
  border: 2px dashed #1976d2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 15px;
}

.inner-add-admin-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-admin-container p {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #565b60;
  text-align: center;
  margin-bottom: 20px;
}

.admin-container {
  height: 170px;
  width: 170px;
  border-radius: 16px;
  border: 1.5px solid #1976d2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 25px;
  position: relative;
}

.delete-admin {
  position: absolute !important;
  top: -15px !important;
  right: -18px !important;
  display: none !important;
}
.admin-container:hover .delete-admin {
  display: block !important;
  transition: 5s;
}

.add-admin-modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px !important;
  padding: 2.8% 3%;
  border: none !important;
  box-shadow: 0px 0px rgba(48, 73, 191, 0.05) !important;
}
.modal-title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-left: 20px;
  color: #0288d1;
}
.add-admin-modal-content {
  display: flex;
  flex-direction: column;
  margin: 30px 20px 10px;
  align-items: center;
  row-gap: 20px;
}

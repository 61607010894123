/* USER TABLE */
.tooltip-button {
  width: 160px !important;
  height: 50px !important;
  border: none !important;
  display: flex !important;
  justify-content: flex-start !important;
  gap: 5px !important;
  padding-left: 22px !important;
}
/* .delete-button {
  color: #f6416c !important;
}
.delete-button:hover {
  background-color: #fff4f7 !important;
} */
/* ADD USER MODAL */
.add-user-modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px !important;
  padding: 4% 3%;
  border: none !important;
  box-shadow: 0px 0px rgba(48, 73, 191, 0.05) !important;
}
.modal-title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-left: 20px;
  color: #0288d1;
}
.modal-content {
  display: flex;
  flex-direction: column;
  margin: 30px 20px 40px;
  align-items: center;
  row-gap: 20px;
}
.modal-content > div {
  width: 100%;
  display: flex;
  gap: 20px;
}
.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-right: 20px;
}
.add-button,
.cancel-button {
  width: 88px;
  font-weight: 600 !important;
}
.add-button {
  color: #0288d1 !important;
  border: 2px solid #0288d1 !important;
}
.cancel-button {
  color: #fff !important;
  border: 2px solid #f6416c !important;
  background-color: #f6416c !important;
}
/* FILTER COMPONENT */
.filter-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.filter-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.3);
}
.filter-content {
  padding: 50px 32px 30px 24px;
  background: #fff;
  z-index: 4;
  width: 350px;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: -100%;
  transition-property: all;
  transition-duration: 2s;
  transition-timing-function: linear;
  transition-delay: 0s;
}
.filter-content.active {
  right: 0px;
  transition-property: all;
  transition-duration: 2s;
  transition-timing-function: linear;
  transition-delay: 0s;
}
.filter-content-header {
  display: flex;
  align-items: center;
  gap: 20px;
}
.filter-content-header > h1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  font-size: 28px;
  line-height: 32px;
}
.filter-inputs {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}
.filter-content.active {
  overflow-y: scroll;
}
/* .filter-content.active::-webkit-scrollbar {
  height: 0;
  width: 0;
} */
.filter-buttons-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
.filter-button {
  color: #0288d1 !important;
  border: 2px solid #0288d1 !important;
  font-weight: 600 !important;
}
.reset-button {
  color: #fff !important;
  border: 2px solid #f6416c !important;
  background-color: #f6416c !important;
  font-weight: 600 !important;
}

/* SHOW MORE INFOS */
.show-more-infos-content {
  background: #fff;
  padding: 32px;
}

.edit-user-error-msg {
  font-weight: 600;
  font-size: 12px;
  color: #f6416c;
  margin-top: 10px;
}

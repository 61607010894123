* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
a {
  text-decoration: none;
}
button {
  text-transform: none !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 13px 14px !important;
}
.MuiTooltip-tooltip {
  background: #fff !important;
  border: 2px solid #dde1e5 !important;
  padding: 0px !important;
  display: flex !important;
  flex-direction: column !important;
}
.MuiPaginationItem-page.Mui-selected {
  background-color: #f6416c !important;
  color: #ffffff !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline,
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: #f6416c !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #f6416c !important;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.page-wrapper {
  padding: 45px 50px;
  background: #f6f7fb;
  min-height: calc(100vh - 67px);
}
.page-settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 20px;
}
.page-infos {
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.page-title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #565b60;
}
.settings-buttons {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.show-more-infos-content {
  background: #fff;
  padding: 32px;
}

.menu-table {
  display: flex;
  align-items: center;
}

/* DELETE MODAL */
.delete-modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px !important;
  padding: 3% 3%;
  border: none !important;
  box-shadow: 0px 0px rgba(48, 73, 191, 0.05) !important;
}
.delete-modal-title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  /* margin-left: 20px; */
  color: #0288d1;
  margin-bottom: 20px;
}
.delete-modal-content {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-bottom: 40px;
  font-weight: 400;
}

.delete-modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.confirm-delete-button {
  color: #fff !important;
  border: 2px solid #f6416c !important;
  background-color: #f6416c !important;
  font-weight: 600 !important;
}
.cancel-delete-button {
  color: #0288d1 !important;
  border: 2px solid #0288d1 !important;
  font-weight: 600 !important;
}

/*JOKE TABLE */
.tooltip-button {
  width: 160px !important;
  height: 50px !important;
  border: none !important;
  display: flex !important;
  justify-content: flex-start !important;
  gap: 5px !important;
  padding-left: 22px !important;
}
.delete-button {
  color: #f6416c !important;
}
.delete-button:hover {
  background-color: #fff4f7 !important;
}
/* ADD JOKE MODAL */
.add-joke-modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px !important;
  padding: 2% 3%;
  border: none !important;
  box-shadow: 0px 0px rgba(48, 73, 191, 0.05) !important;
  display: flex;
  flex-direction: column;
  width: 600px;
}
.joke-modal-content {
  /* display: flex; */
  /* flex-direction: column; */
  margin: 20px 0px 20px;
  /* justify-content: center; */
}
.add-joke-image {
  height: 171px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.error {
  border: 1px solid #f6416c;
}
.add-joke-image p {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
}
.add-joke-image input,
.show-joke-image input {
  display: none;
}
.show-joke-image {
  height: 171px;
  border-radius: 4px;
  position: relative;
}
.show-joke-image video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.show-joke-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.add-joke-image-bttn {
  position: absolute !important;
  top: -20px !important;
  right: -20px !important;
}

/* SHOW MORE INFOS */
.show-more-infos-content {
  background: #fff;
  padding: 32px;
}
.show-more-video-image {
  width: 50%;
  height: 300px;
}
.show-more-video-image img,
.show-more-video-image video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.upvoted-container {
  margin-bottom: 8px;
  padding: 20px 25px 10px 10px;
  box-shadow: 0 0 10px 3px hsl(0deg 0% 50% / 10%);
  position: relative;
}
.delete-upvoted-icon {
  position: absolute !important;
  top: 0px !important;
  right: -5px !important;
}

.add-joke-error-msg {
  font-weight: 600;
  font-size: 12px;
  color: #f6416c;
  margin-top: 10px;
}
.edit-joke-image {
  height: 250px;
  border-radius: 4px;
  position: relative;
}
.edit-joke-image video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.edit-joke-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.edit-joke-image input {
  display: none;
}

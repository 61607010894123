.navbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px 10px 30px;
  border-bottom: 1px solid #dde1e5;
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 2;
}
.navbar-right-side {
  position: relative;
  padding-right: 30px;
}

.admin-infos {
  display: flex;
  align-items: center;
}
.admin-infos > h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.admin-menu {
  width: 100%;
  position: absolute;
  top: 43px;
  right: 0px;
  /* border: 1px solid #dde1e5; */
  border-top: 0px;
  background: #fff;
}

/* SIDE BAR */
.side-bar-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}
.side-bar-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.3);
}
.side-bar {
  z-index: 4;
  background: #fff;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 0px;
  padding-left: 40px;
}
.side-bar-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 80px;
  width: 100%;
}
.side-bar-item {
  list-style: none;
  height: 60px;
  display: flex;
  width: 100%;
}
.side-bar-item > a {
  color: black;
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 18px;
}

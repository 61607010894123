/* CATEGORY TABLE */
.tooltip-button {
  width: 160px !important;
  height: 50px !important;
  border: none !important;
  display: flex !important;
  justify-content: flex-start !important;
  gap: 5px !important;
  padding-left: 22px !important;
}
.delete-button {
  color: #f6416c !important;
}
.delete-button:hover {
  background-color: #fff4f7 !important;
}
/* ADD CATEGORY MODAL */
.add-category-modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px !important;
  padding: 4% 3%;
  border: none !important;
  box-shadow: 0px 0px rgba(48, 73, 191, 0.05) !important;
}
.modal-title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-left: 20px;
  color: #0288d1;
}
.category-modal-content {
  margin: 30px 20px 40px;
}
.category-modal-content > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* FILTER CATEGORY */
.filter-category-inputs {
  margin: 40px 0px 90px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}
/* SHOW MORE INFOS */
.show-more-infos-content {
  background: #fff;
  padding: 32px;
}

.add-category-error-msg {
  font-weight: 600;
  font-size: 12px;
  color: #f6416c;
  margin-top: -12px;
}

.login-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 40px 60px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  box-shadow: 0 0 10px 3px hsl(0deg 0% 50% / 10%);
}
.login-title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #f6416c;
  margin: 20px 0px 29px;
}
.login-descreption {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #979797;
  max-width: 230px;
  margin-bottom: 37px;
}
.login-inputs-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
}

.login-button {
  width: 100%;
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  margin-top: 30px !important;
  padding: 12px 0px !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px rgba(42, 42, 42, 0.06) !important;
}

.login-error-msg {
  font-weight: 600;
  font-size: 12px;
  color: #f6416c;
  margin-top: -12px;
}
